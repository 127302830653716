import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_API_URL } from 'services/services.constants';

import { API_PATH_ROUTES, TAG_TYPES } from './auth.constants';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_URL,
    credentials: 'include',
  }),
  tagTypes: [TAG_TYPES.logIn, TAG_TYPES.logOut],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: `${API_PATH_ROUTES.logIn}`,
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: [TAG_TYPES.logIn],
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${API_PATH_ROUTES.logOut}`,
        method: 'POST',
      }),
      invalidatesTags: [TAG_TYPES.logOut],
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
