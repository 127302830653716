import { createSlice } from '@reduxjs/toolkit';

import { ProjectNameEnums } from 'types';

export interface ISettings {
  currentProject: ProjectNameEnums;
}

const initialState: ISettings = {
  currentProject: ProjectNameEnums.PortalCurp,
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCurrentProject: (state, { payload }: { payload: ProjectNameEnums }) => {
      state.currentProject = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentProject } = settings.actions;
