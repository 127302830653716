import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { TAllOrderConsults, TAllOrderService, TPeruOrderServices } from 'types';
import { IDateRange } from './oreders.types';

import { BASE_API_URL } from 'services/services.constants';
import { TAG_TYPES, API_PATH_ROUTES } from './orders.constants';

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_URL,
    credentials: 'include',
  }),
  tagTypes: [
    TAG_TYPES.consult,
    TAG_TYPES.service,
    TAG_TYPES.peruOrdersByTable,
    TAG_TYPES.peru,
    TAG_TYPES.approvedServicesByDateRange,
    TAG_TYPES.approvedServicesByProjectNameDateRange,
  ],
  endpoints: (builder) => ({
    getConsultOrders: builder.query<
      TAllOrderConsults[],
      { projectName: string | null }
    >({
      query: ({ projectName }) =>
        `${API_PATH_ROUTES.consultOrder}/${projectName}`,
      providesTags: [TAG_TYPES.consult],
    }),
    getServicesOrdersByProjectName: builder.query<
      TAllOrderService[],
      { projectName: string | null }
    >({
      query: ({ projectName }) =>
        `${API_PATH_ROUTES.serviceOrder}/${projectName}`,
      providesTags: [TAG_TYPES.consult],
    }),
    getAllPeruOrders: builder.query<TPeruOrderServices[], null>({
      query: () => `${API_PATH_ROUTES.peruOrder}`,
      providesTags: [TAG_TYPES.peru],
    }),
    getPeruOrdersByTable: builder.query<
      TPeruOrderServices[],
      { tableName: string | null }
    >({
      query: ({ tableName }) => `${API_PATH_ROUTES.peruOrder}/${tableName}`,
      providesTags: [TAG_TYPES.peruOrdersByTable],
    }),
    getApprovedServiceByDateRange: builder.query<
      TAllOrderService[],
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) =>
        `${API_PATH_ROUTES.approvedServicesByDateRange}?startDate=${startDate}&endDate=${endDate}`,
      providesTags: [TAG_TYPES.approvedServicesByDateRange],
    }),
    getApprovedServiceByProjectNameDateRange: builder.query<
      TAllOrderService[],
      { dateRange: IDateRange; projectRoute: string }
    >({
      query: ({ dateRange, projectRoute }) => {
        const { startDate, endDate } = dateRange;
        return `${API_PATH_ROUTES.approvedServices}/${projectRoute}/date-range?startDate=${startDate}&endDate=${endDate}`;
      },
      providesTags: [TAG_TYPES.approvedServicesByProjectNameDateRange],
    }),
  }),
});

export const {
  useGetConsultOrdersQuery,
  useGetServicesOrdersByProjectNameQuery,
  useGetAllPeruOrdersQuery,
  useGetPeruOrdersByTableQuery,
  useGetApprovedServiceByDateRangeQuery,
  useGetApprovedServiceByProjectNameDateRangeQuery,
} = ordersApi;
